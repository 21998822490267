.content-block li {
    margin-bottom: 20px
}

.content-block {
    color: black
}

.content-block h3 {
    color: #4B4B4B
}

.content-block p {
    font-size: 16px;
}

.content-block h3 {
    margin-top: 60px;
    font-size: 24px;
    font-weight: 700;
}

.content-block img {
    margin: 30px 0
}