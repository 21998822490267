.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F1E6D3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

html {
  @apply text-primary
}

h1 {
  font-family: 'Tobias';
  font-size: xx-large;
}
h2 {
  font-family: 'Tobias';
  font-size: x-large;
}

p {
  font-family: 'Nunito Sans';

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Normal font */
@font-face {
  font-family: 'Tobias';
  src: url('../public/TobiasVF.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Italic font */
@font-face {
  font-family: 'Tobias';
  src: url('../public/TobiasItalicVF.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}